import { useAtomValue } from 'jotai'
import { useEffect } from 'react'

import { mobileMenuAtom } from '#/core/stores'

import type { NavigationItem, ProfileMenuItem } from '../types'
import { NavItem } from './navigation-item'
import styles from './overlay.module.scss'
import { Profile } from './profile'

type Props = {
  navRef: React.RefObject<HTMLElement>
  navigationItems: NavigationItem[]
  profileMenuItems: ProfileMenuItem[]
}

export const MobileMenuOverlay: React.FC<Props> = ({ navRef, navigationItems, profileMenuItems }) => {
  const { isVisible } = useAtomValue(mobileMenuAtom)

  useEffect(() => {
    if (isVisible) {
      document.body.classList.add('no-scroll')
    } else {
      document.body.classList.remove('no-scroll')
    }
  }, [isVisible])

  return (
    <div
      className={styles.backdrop}
      style={{ paddingTop: navRef.current?.getBoundingClientRect().height ?? 0 }}
      data-visible={isVisible ? 'true' : undefined}
    >
      <div className={styles.content}>
        <Profile items={profileMenuItems} />

        <div className={styles.separator} />

        <div className={styles.group}>
          {navigationItems.map((props, index) => <NavItem {...props} key={index} />)}
        </div>
      </div>
    </div>
  )
}

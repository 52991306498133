import { useAsyncEffect, useLocalStorageState } from 'ahooks'
import { useSetAtom } from 'jotai'
import { Helmet } from 'react-helmet-async'
import { match, P } from 'ts-pattern'

import { LoadingOverlay } from '#/components/views/loading/overlay'
import handlers from '#/core/handlers'
import { playingGamesAtom } from '#/core/stores'
import { useCaptureScreen } from '#/hooks'
import { useUserLibrary } from '#/hooks/query'

import { withAuthentication } from '../hoc/with-authentication'
import { ValidatingIndicator } from '../views/indicators/validate'
import { LibraryAside, LibraryDetail, LibraryPlaceholder } from '../views/library'
import styles from './library.module.scss'

const LibraryScreen: React.FC = () => {
  useCaptureScreen('Library')

  const [selectedGame, setSelectedGame] = useLocalStorageState<
    string | undefined
  >('selected_game', undefined)

  const setPlayingGames = useSetAtom(playingGamesAtom)
  const { isLoading, data } = useUserLibrary()

  useAsyncEffect(async () => {
    const playing = (await handlers.getPreviousPlayingSessions()) || []
    setPlayingGames(draft => {
      const next = new Set(draft)
      playing.forEach(slug => next.add(slug))

      return next
    })
  }, [])

  return (
    <main className={styles.screen}>
      <Helmet>
        <title>Library - Mavis Hub</title>
      </Helmet>

      {match({ isLoading, data })
        .with({ isLoading: true }, () => <LoadingOverlay fit />)
        .with({ data: undefined }, () => null)
        .with({ data: P.not(P.nullish) }, () => (
          <div className={styles.container}>
            <aside className={styles.aside}>
              <LibraryAside selectedGame={selectedGame} setSelectedGame={setSelectedGame} />
            </aside>
            <section className={styles.details}>
              {match(selectedGame)
                .with(undefined, () => <LibraryPlaceholder />)
                .otherwise((selected) => <LibraryDetail selected={selected} />)}
            </section>
            <ValidatingIndicator isVisible={isLoading} />
          </div>
        ))
        .exhaustive()}
    </main>
  )
}

export const WrappedLibraryScreen = withAuthentication(LibraryScreen)

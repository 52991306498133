import { ChevronDownIcon, Login01Icon, UserIcon } from '@axieinfinity/dango-icons'
import { Typography } from '@axieinfinity/konan'
import cx from 'classnames'
import { useSetAtom } from 'jotai'
import { useReducer, useRef } from 'react'
import { toast } from 'sonner'

import { Skeleton } from '#/components/common/loader'
import { Tooltip } from '#/components/common/tooltip'
import { loginDialogAtom, mobileMenuAtom } from '#/core/stores'
import { useCaptureEvent, useHandler } from '#/hooks'
import { useUserProfile } from '#/hooks/query'
import { truncateAddress } from '#/utils'

import { ProfileMenuItem } from '../types'
import styles from './profile.module.scss'
import sharedStyles from './shared.module.scss'

type Props = {
  items: ProfileMenuItem[]
}

export const Profile: React.FC<Props> = ({ items }) => {
  const captureEvent = useCaptureEvent('mobile-menu|profile')
  const { handleSetClipboard } = useHandler()
  const { data: profile } = useUserProfile()

  const setLoginDialog = useSetAtom(loginDialogAtom)
  const setMobileMenu = useSetAtom(mobileMenuAtom)

  const dropdownContentRef = useRef<HTMLDivElement>(null)
  const [isExpanded, toggleIsExpanded] = useReducer((prevState) => !prevState, true)

  if (profile === undefined) {
    return (
      <div className={styles.loader}>
        <Skeleton width={40} corner="rounded-full" />
        <Skeleton width={160} height={40} corner="rounded" />
        <Skeleton size={40} />
      </div>
    )
  }

  function openLogInDialog() {
    captureEvent('Click Login')
    setMobileMenu({ isVisible: false })
    setLoginDialog({ isVisible: true })
  }

  if (profile === null) {
    return (
      <button className={cx(sharedStyles.button, sharedStyles.block)} onClick={openLogInDialog}>
        <Login01Icon size={20} />
        Log in
      </button>
    )
  }

  const roninAddress = profile.socialAccounts.find(({ authType }) => authType === 'ronin')?.socialID

  function handleCopyAddressToClipboard(text: string) {
    return () => {
      captureEvent('Click Ronin address to copy')
      handleSetClipboard(text)?.then(() => toast.message('Copied to clipboard!'))
    }
  }

  function handleMenuItemClick(label: string, callback: () => void, autoClose = true) {
    return () => {
      captureEvent(`Click on ${label}`)
      if (autoClose) {
        setMobileMenu({ isVisible: false })
      }
      callback()
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <div className={styles.avatar}>
          <UserIcon className={styles.icon} size={24} />
        </div>

        <div className={styles.information}>
          <Typography.Text className={styles.name} level="1">
            {profile.name}
          </Typography.Text>

          {typeof roninAddress === 'string' && (
            <Tooltip side="bottom" content="Click to copy" >
              <Typography.Text
                className={styles.address}
                level="3"
                onClick={handleCopyAddressToClipboard(roninAddress)}
              >
                {truncateAddress(roninAddress)}
              </Typography.Text>
            </Tooltip>
          )}
        </div>

        <button
          className={sharedStyles.button}
          data-active={isExpanded ? 'true' : undefined}
          onClick={toggleIsExpanded}
        >
          <ChevronDownIcon size={20} data-slot="suffix" />
        </button>
      </div>

      <div
        className={styles.dropdown}
        style={{ height: isExpanded ? dropdownContentRef.current?.getBoundingClientRect().height : 0 }}
      >
        <div className={styles.content} ref={dropdownContentRef}>
          {items.map(({ label, isNegative, autoCloseMenu, onClick }, index) => (
            <button
              className={cx(styles.item, { [styles.negative]: isNegative })}
              key={index}
              onClick={handleMenuItemClick(label, onClick, autoCloseMenu)}
            >
              {label}
            </button>
          ))}
        </div>
      </div>
    </div>
  )
}

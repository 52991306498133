import { getSearchParams, PaginationInput } from '@axieinfinity/hub-services'
import { useQuery } from '@tanstack/react-query'
import { toast } from 'sonner'

import { errorMessages } from '#/constants'
import { logger } from '#/core/logger'
import { services } from '#/core/services'

const defaultPagination = {
	lastPage: 1,
	totalRows: 0,
	desc: false,
	pageSize: 18,
	page: 1,
}
const defaultResponse = { data: [], pagination: defaultPagination }

export const publicGamesKeys = {
	all: ['public_games'] as const,
	filter: (
		filters: Record<string, Array<string> | string>,
		pagination: PaginationInput
	) => [...publicGamesKeys.all, filters, pagination] as const,
}
export const usePublicGames = (
	filters: Record<string, Array<string> | string>,
	pagination: PaginationInput
) => {
	return useQuery({
		queryKey: publicGamesKeys.filter(filters, pagination),
		queryFn: async () => {
			try {
				const { response, error, result } = await services.request(
					'post /v2/public/games?[query]',
					{
						query: getSearchParams(pagination).toString(),
						body: filters,
					}
				)
				if (response.status >= 500) {
					toast.error(errorMessages.serverBusy)

					return defaultResponse
				} else if (error) {
					return defaultResponse
				} else {
					return result
				}
			} catch (e) {
				logger.error(e, { error: e }, 'usePublicGames')

				return defaultResponse
			}
		},
	})
}

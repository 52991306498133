import { AppleIcon, WindowsIcon } from '@axieinfinity/dango-icons'
import { Button, Intent } from '@axieinfinity/konan'
import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { match, P } from 'ts-pattern'
import UAParser from 'ua-parser-js'

import { useCaptureScreen } from '#/hooks/tracking'

import styles from './about.module.scss'

type OS = 'legacyWindows' | 'windows' | 'macos'
type DownloadData = {
  os: OS
  directLink: string
  description: React.ReactNode
}

const downloadDataList: Record<OS, DownloadData> = {
  windows: {
    os: 'windows',
    directLink: 'https://axieinfinity.com/downloads/mavis_hub-latest-windows.exe',
    description: 'Windows 10/11',
  },
  legacyWindows: {
    os: 'legacyWindows',
    directLink: 'https://axieinfinity.com/downloads/mavis_hub-latest-legacy-windows.exe',
    description: 'Windows 7/8/8.1',
  },
  macos: {
    os: 'macos',
    directLink: 'https://axieinfinity.com/downloads/mavis_hub-latest-macos.dmg',
    description: 'MacOS',
  },
}

export const AboutScreen: React.FC = () => {
  useCaptureScreen('About')

  const [downloadData, setDownloadData] = useState<DownloadData | null>(null)
  const [isDownloadPressed, setIsDownloadPressed] = useState<boolean>(false)

  function handleDownloadClick(url: string) {
    return () => {
      const anchorTag = document.createElement('a')
      anchorTag.href = url
      anchorTag.download = 'true'
      anchorTag.click()
      setIsDownloadPressed(true)
    }
  }

  useEffect(() => {
    if (navigator) {
      const userAgent = UAParser(navigator.userAgent)

      match(userAgent.os)
        .with({ version: P.nullish }, () => undefined)
        .with(
          { name: 'Windows', version: P.when((v) => ['7', '8', '8.1'].includes(v as string)) },
          () => setDownloadData(downloadDataList.legacyWindows)
        )
        .with({ name: 'Windows' }, () => setDownloadData(downloadDataList.windows))
        .with({ name: 'Mac OS' }, () => setDownloadData(downloadDataList.macos))
        .otherwise(() => undefined)
    }
  }, [])

  useEffect(() => {
    if (isDownloadPressed) {
      const timeoutId = setTimeout(() => setIsDownloadPressed(false), 3_000)

      return () => {
        clearTimeout(timeoutId)
      }
    }
  }, [isDownloadPressed])

  return (
    <div className={styles.root}>
      <div data-slot="background_container">
        <img height="100%" loading="lazy" src="/about/background.png" data-slot="background" />
        <img height="100%" loading="lazy" src="/about/background.png" data-slot="background" />
      </div>

      <div data-slot="boundary">
        <div data-slot="content">
          <img height="260px" src="/about/product-preview.png" data-slot="preview" />

          <figure data-slot="figure">
            <img height={36} src="/logo.svg" data-slot="fig_image" />

            {downloadData ? (
              <>
                <div data-slot="fig_caption">
                  <div data-slot="heading">
                    Mavis Hub
                  </div>

                  <div data-slot="description">
                    Mavis Hub is the final destination of Web3 games on
                    Ronin network and unlock an amazing gaming experience.
                  </div>
                </div>

                <Button
                  intent={Intent.Primary}
                  icon={
                    match(downloadData.os)
                      .with('windows', 'legacyWindows', () => WindowsIcon)
                      .with('macos', () => AppleIcon)
                      .otherwise(() => undefined)
                  }
                  text={(
                    <AnimatePresence mode="wait">
                      {isDownloadPressed ? (
                        <motion.div
                          key="starting-indicator"
                          initial={{ opacity: 0, translateY: -10 }}
                          animate={{ opacity: 1, translateY: 0 }}
                          exit={{ opacity: 0, translateY: -10 }}
                        >
                          Starting...
                        </motion.div>
                      ) : (
                        <motion.div
                          key="starting-"
                          initial={{ opacity: 0, translateY: 10 }}
                          animate={{ opacity: 1, translateY: 0 }}
                          exit={{ opacity: 0, translateY: 10 }}
                        >
                          Install Mavis Hub
                        </motion.div>
                      )}
                    </AnimatePresence>
                  )}
                  onClick={handleDownloadClick(downloadData.directLink)}
                />

                <div data-slot="additional_message">
                  Also available on: {
                    match(downloadData.os)
                      .with('legacyWindows', () => (
                        <>
                          <Link to={downloadDataList.macos.directLink} download data-slot="anchor">
                            {downloadDataList.macos.description}
                          </Link>
                          {' '}and{' '}
                          <Link to={downloadDataList.windows.directLink} download data-slot="anchor">
                            {downloadDataList.windows.description}
                          </Link>
                        </>
                      ))
                      .with('windows', () => (
                        <>
                          <Link to={downloadDataList.legacyWindows.directLink} download data-slot="anchor">
                            {downloadDataList.legacyWindows.description}
                          </Link>
                          {' '}and{' '}
                          <Link to={downloadDataList.macos.directLink} download data-slot="anchor">
                            {downloadDataList.macos.description}
                          </Link>
                        </>
                      ))
                      .with('macos', () => (
                        <>
                          <Link to={downloadDataList.windows.directLink} download data-slot="anchor">
                            {downloadDataList.windows.description}
                          </Link>
                          {' '}and{' '}
                          <Link to={downloadDataList.legacyWindows.directLink} download data-slot="anchor">
                            {downloadDataList.legacyWindows.description}
                          </Link>
                        </>
                      ))
                      .otherwise(() => null)
                  }
                </div>
              </>
            ) : (
              <>
                <div data-slot="fig_caption">
                  <div data-slot="heading">
                    Install Our Mavis Hub Desktop Launcher
                  </div>

                  <div data-slot="description">
                    Mavis Hub is the final destination of Web3 games on
                    Ronin network and unlock an amazing gaming experience.
                  </div>
                </div>

                <div data-slot="unavailable-message">Available on Windows and macOS</div>
              </>
            )}
          </figure>
        </div>
      </div>
    </div>
  )
}

import { UserIcon } from '@axieinfinity/dango-icons'
import { Button, ButtonVariant, Typography } from '@axieinfinity/konan'
import cx from 'classnames'
import { useSetAtom } from 'jotai'
import { toast } from 'sonner'

import { Skeleton } from '#/components/common/loader/skeleton'
import { Tooltip } from '#/components/common/tooltip'
import { loginDialogAtom } from '#/core/stores'
import { useCaptureEvent, useHandler } from '#/hooks'
import { useUserProfile } from '#/hooks/query'
import { truncateAddress } from '#/utils'

import type { ProfileMenuItem } from '../types'
import styles from './profile.module.scss'
import { ProfileMenu } from './profile-menu'

type Props = {
  items: ProfileMenuItem[]
}

export const Profile: React.FC<Props> = ({ items }) => {
  const captureEvent = useCaptureEvent()
  const { handleSetClipboard } = useHandler()
  const { data: profile, isLoading } = useUserProfile()

  const setLoginDialog = useSetAtom(loginDialogAtom)

  if (profile === undefined) {
    return (
      <div className={styles.container}>
        <Skeleton width={40} corner="rounded-full" />
        <Skeleton width={160} height={40} corner="rounded" />
        <Skeleton size={40} />
      </div>
    )
  }

  function openLogInDialog() {
    captureEvent('Click Login')
    setLoginDialog({ isVisible: true })
  }

  if (profile === null) {
    return <Button variant={ButtonVariant.Plain} text="Log in" onClick={openLogInDialog} />
  }

  const roninAddress = profile.socialAccounts.find(({ authType }) => authType === 'ronin')?.socialID

  function handleCopyAddressToClipboard(text: string) {
    return () => {
      captureEvent('Click Ronin address to Copy')
      handleSetClipboard(text)?.then(() => toast.message('Copied to clipboard!'))
    }
  }

  return (
    <div className={cx(styles.container, { [styles.loading]: isLoading })}>
      <div className={styles.avatar}>
        <UserIcon className={styles.icon} size={24} />
      </div>

      <div className={styles.information}>
        <Typography.Text className={styles.name} level="1">
          {profile.name}
        </Typography.Text>

        {typeof roninAddress === 'string' && (
          <Tooltip side="bottom" content="Click to copy" >
            <Typography.Text
              className={styles.address}
              level="3"
              onClick={handleCopyAddressToClipboard(roninAddress)}
            >
              {truncateAddress(roninAddress)}
            </Typography.Text>
          </Tooltip>
        )}
      </div>

      <ProfileMenu items={items} />
    </div>
  )
}

import { MoreVerticalIcon } from '@axieinfinity/dango-icons'
import { Button, ButtonVariant, Popover, Typography } from '@axieinfinity/konan'
import cx from 'classnames'
import { useCallback, useState } from 'react'
import { match, P } from 'ts-pattern'

import { useCaptureEvent } from '#/hooks'

import type { ProfileMenuItem } from '../types'
import styles from './profile-menu.module.scss'

type Props = {
  items: ProfileMenuItem[]
}

export const ProfileMenu: React.FC<Props> = ({ items }) => {
  const captureEvent = useCaptureEvent()

  const [isOpen, setIsOpen] = useState<boolean>(false)

  const openMenu = useCallback(() => {
    captureEvent('Profile - Open Menu')
    setIsOpen(true)
  }, [captureEvent])

  const closeMenu = useCallback(() => {
    setIsOpen(false)
  }, [])

  const handleItemClick = useCallback((label: string, callback: () => void) => {
    return () => {
      captureEvent(`Profile - Click ${label}`)
      closeMenu()
      callback()
    }
  }, [captureEvent, closeMenu])

  return (
    <Popover
      layerClassName={styles.popover}
      isOnDialog
      isOpen={isOpen}
      triggerElement={
        <Button
          className={styles.trigger}
          variant={ButtonVariant.Plain}
          text={<MoreVerticalIcon size={20} />}
          onClick={openMenu}
        />
      }
      onOutsideClick={closeMenu}
    >
      {items.map(({ label, symbol, isNegative, onClick }, index) => (
        <Typography.Text
          className={cx(styles.item, { [styles.negative]: isNegative })}
          key={index}
          onClick={handleItemClick(label, onClick)}
        >
          {match(symbol)
            .with(P.not(P.nullish), (Element) => <Element />)
            .otherwise(() => null)}

          <span>{label}</span>
        </Typography.Text>
      ))}
    </Popover>
  )
}

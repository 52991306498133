import { GameDetails } from '@axieinfinity/hub-services'
import { useResponsive } from '@axieinfinity/konan'
import debounce from 'lodash.debounce'
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef } from 'react'
import { match } from 'ts-pattern'

import { Back } from '#/components/common/back'
import { Badge } from '#/components/common/badge'
import { gameDetailsContainerId } from '#/components/screens/game-details'
import { useCaptureEvent, useGame } from '#/hooks'

import { Controls } from './controls'
import styles from './header.module.scss'

const spacing = 24

export const HeaderSection: React.FC<GameDetails> = (game) => {
  const captureEvent = useCaptureEvent()
  const { lg: isLargeDimension } = useResponsive()
  const { isAddedToLibrary } = useGame(game)

  const sectionRef = useRef<HTMLElement>(null)
  const tagContainerRef = useRef<HTMLDivElement>(null)

  const [prevPage, prevPageName] = useMemo(() => {
    const searchParams = new URLSearchParams(window.location.search)
    let prevPage = '/'
    let prevPageName = 'Home'
    searchParams.forEach((value, key) =>
      match({ key, value })
        .with({ key: 'from' }, ({ value }) => (prevPage = value))
        .with({ key: 'name' }, ({ value }) => (prevPageName = value))
        .with({ key: 'filter' }, ({ value }) => {
          prevPage += `?${decodeURIComponent(value)}`
        })
        .otherwise(() => null)
    )

    return [prevPage, prevPageName]
  }, [])

  function onClickBack() {
    captureEvent(`Click Back to ${prevPageName}`, {
      from_location: window.location.href,
    })
  }

  const updateContainerPadding = useCallback(() => {
    const sectionHeight = sectionRef.current?.getBoundingClientRect().height
    const container = document.getElementById(gameDetailsContainerId)
    if (sectionHeight !== undefined && container) {
      container.style.paddingTop = `${sectionHeight + spacing}px`
    }
  }, [])

  useLayoutEffect(() => {
    const nameElement = document.getElementById('game-header-name')
    if (tagContainerRef.current && nameElement) {
      nameElement.style.maxWidth = `calc(100% - var(--spacing) - ${tagContainerRef.current.getBoundingClientRect().width}px)`
    }
  }, [isAddedToLibrary, game])

  useEffect(() => {
    const handleResize = () => {
      updateContainerPadding()
    }

    const debouncedResizeHandler = debounce(handleResize, 200)

    handleResize()
    window.addEventListener('resize', debouncedResizeHandler)

    return () => {
      window.removeEventListener('resize', debouncedResizeHandler)
    }
  }, [isAddedToLibrary, game, updateContainerPadding])

  return (
    <section
      className={styles.section}
      ref={sectionRef}
      id="game-details-header"
    >
      <div className={styles.container}>
        <nav className={styles.navigation}>
          <Back href={prevPage} onClick={onClickBack}>
            Back to {prevPageName}
          </Back>
        </nav>

        <div className={styles.body}>
          <div className={styles.containerLayer}>
            <div className={styles.absoluteLayer}>
              <figure className={styles.figure}>
                <img className={styles.image} src={game.logoUrl} />
                <figcaption className={styles.caption}>
                  <div className={styles.tags} ref={tagContainerRef}>
                    {isAddedToLibrary && <Badge.InLibrary />}
                    {game.mode === 'coming-soon' && <Badge.ComingSoon />}
                    {game.metaData?.includes('early-testing') && (
                      <Badge.Greenlight />
                    )}
                    {game.event?.code === 'agj-2023' && (
                      <Badge.AxieGameJam year={2023} />
                    )}
                  </div>
                  <div
                    className={styles.name}
                    id="game-header-name"
                    style={
                      isLargeDimension
                        ? { maxWidth: 'calc(100% - var(--spacing))' }
                        : undefined
                    }
                  >
                    <div className={styles.placeholder}>{game.name}</div>
                    <div className={styles.display}>
                      <div>{game.name}</div>
                    </div>
                  </div>
                  <div className={styles.description}>{game.description}</div>
                </figcaption>
              </figure>
            </div>
          </div>

          {isLargeDimension && (
            <Controls
              className={styles.controls}
              minimal
              game={game}
            />
          )}
        </div>
      </div>
    </section>
  )
}

import { Button, ButtonVariant, Typography } from '@axieinfinity/konan'
import { GameMobileDownloadResource, SupportPlatformOs } from '@axieinfinity/hub-services'
import { useState } from 'react'

import { QR } from '#/components/common/qr'
import { useCaptureEvent } from '#/hooks'
import { formatPlatformOs } from '#/utils'

import styles from './play-on-mobile-content.module.scss'

const logoSource: Partial<Record<SupportPlatformOs, string>> = {
	android: '/assets/qr-android.svg',
	ios: '/assets/qr-apple.svg',
}

export const PlayOnMobileContent: React.FC<{
	resource: {
		mobileResources: Array<GameMobileDownloadResource | undefined>
		name: string
	}
}> = ({ resource }) => {
	const captureEvent = useCaptureEvent()
	const [tab, setTab] = useState(0)

	const selectedResource = resource?.mobileResources[tab]
	if (!selectedResource) return null
	if (resource === null) return null

	return (
		<section className={styles.section}>
			<Typography.Heading level="5">Download on Mobile</Typography.Heading>
			<div
				className={styles.tabs}
				style={{
					gridTemplateColumns: Array(resource.mobileResources.length).fill('1fr').join(' '),
				}}
			>
				{resource.mobileResources.map((resource, index) => (
					<Button
						text={`${formatPlatformOs(resource?.os)}`}
						key={index}
						variant={tab === index ? ButtonVariant.Default : ButtonVariant.Plain}
						className={styles.tab}
						onClick={() => setTab(index)}
					/>
				))}
			</div>

			<div className={styles.qrCode}>
				<div className={styles.image}>
					<QR value={selectedResource.downloadUrl} logo={logoSource[selectedResource.os]} />
				</div>
			</div>

			<Typography.Text className={styles.description}>
				Scan this QR code or{' '}
				<a href={selectedResource.downloadUrl} className={styles.downloadLink} target="_blank" rel="noreferrer" onClick={() => captureEvent('Click download mobile game via link', { ...selectedResource })}>click here</a>{' '}
				to download <br /> <b>{resource.name}</b> on your mobile device
			</Typography.Text>
		</section>
	)
}

import { ExternalLinkIcon } from '@axieinfinity/dango-icons'
import { Dialog, Typography } from '@axieinfinity/konan'
import { config } from '@mavishub/config'
import { useState } from 'react'

import { useCaptureEvent, useLoginDialog } from '#/hooks'

import styles from './dialog.module.scss'
import { LoginForm } from './form'
import { LoginQRCode } from './mobile-qr'

export const LoginDialog: React.FC = () => {
  const captureEvent = useCaptureEvent()
  const { isVisible, closeLoginDialog } = useLoginDialog()

  const [error, setError] = useState<string>('')

  function handleRegisterClick() {
    captureEvent('Click Register')
  }

  return (
    <Dialog
      className={styles.dialog}
      wrapClassName={styles.dialogContainer}
      size="x-large"
      destroyOnClose
      visible={isVisible}
      onClose={() => closeLoginDialog()}
    >
      <section className={styles.body}>
        <Typography.Heading level="1">Login</Typography.Heading>
        <div className={styles.error}>
          {error && <Typography.Text className={styles.message}>{error}</Typography.Text>}
        </div>

        <div className={styles.main}>
          <LoginForm className={styles.form} setError={setError} onSuccess={() => closeLoginDialog(false)} />
          <LoginQRCode className={styles.qr} setError={setError} />
        </div>

        {config && (
          <footer className={styles.footer}>
            <span>Don&apos;t have account?</span>
            <a
              className={styles.link}
              href={config.accountService}
              target="_blank"
              rel="noopener noreferrer"
              onClick={handleRegisterClick}
            >
              Register now
              <ExternalLinkIcon size={16} />
            </a>
          </footer>
        )}
      </section>
    </Dialog>
  )
}

import { Typography } from '@axieinfinity/konan'
import { GameWithResourcePlatform } from '@axieinfinity/hub-services'

import { isEmpty } from '#/utils'

import styles from './content.module.scss'

type Props = GameWithResourcePlatform

export const TabContent: React.FC<Props> = (platform) => {
	return (
		<div className={styles.container}>
			{(Object.keys(platform.requirements || {}) as (keyof GameWithResourcePlatform['requirements'])[]).map(
				(type) =>
					!isEmpty(platform.requirements?.[type]) && (
						<div className={styles.section} key={type}>
							<Typography.Text className={styles.heading}>{type}</Typography.Text>
							{Object.entries(platform.requirements?.[type] || {}).map(([label, value]) => (
								<div className={styles.row} key={crypto.randomUUID()}>
									<Typography.Text className={styles.label}>{label}</Typography.Text>
									<Typography.Text>{value as string}</Typography.Text>
								</div>
							))}
						</div>
					)
			)}
		</div>
	)
}

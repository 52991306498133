import { DesktopPlatformOs, GameDesktopDownloadResource, GameDownloadResource, GameMobileDownloadResource, GameWebResource, MobilePlatformOs } from '@axieinfinity/hub-services'

import { userOs } from '.'

export function extractWindowResource(resources: GameDownloadResource[] = []) {
	return resources.find(
		(resource): resource is GameDesktopDownloadResource => resource.os === 'windows'
	)
}

export function extractMacOSResource(resources: GameDownloadResource[] = []) {
	return resources.find(
		(resource): resource is GameDesktopDownloadResource => resource.os === 'macos'
	)
}

export function getCurrentOSResource(resources: GameDownloadResource[] = []) {
	return resources.find(
		(resource): resource is GameDesktopDownloadResource => resource.os === userOs
	)
}

export function extractWebResource(resources: GameDownloadResource[] = []) {
	return resources.find(
		(resource): resource is GameWebResource => resource.os === 'web'
	)
}

export function extractIOSResource(resources: GameDownloadResource[] = []) {
	return resources.find(
		(resource): resource is GameMobileDownloadResource => resource.os === 'ios'
	)
}

export function extractAndroidResource(resources: GameDownloadResource[] = []) {
	return resources.find(
		(resource): resource is GameMobileDownloadResource => resource.os === 'android'
	)
}

export function extractMobileResource(resources: GameDownloadResource[] = []) {
	const result: Record<MobilePlatformOs, GameMobileDownloadResource | undefined> = {
		ios: extractIOSResource(resources),
		android: extractAndroidResource(resources),
	}

	return result
}

export function extractDesktopResource(resources: GameDownloadResource[] = []) {
	const result: Record<DesktopPlatformOs, GameDesktopDownloadResource | undefined> = {
		windows: extractWindowResource(resources),
		macos: extractMacOSResource(resources),
	}

	return result
}

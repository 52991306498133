import { COOKIE_NAME } from '@axieinfinity/kukki'
import { StoreInstalledGames } from '@axieinfinity/kataru'
import { GameDetails } from '@axieinfinity/hub-services'
import { useMemoizedFn } from 'ahooks'
import { useAtomValue } from 'jotai'
import { useMemo } from 'react'
import { toast } from 'sonner'
import { match, P } from 'ts-pattern'

import { errorMessages, GameButtonState } from '#/constants'
import handlers from '#/core/handlers'
import { queryClient } from '#/core/react-query'
import { services } from '#/core/services'
import {
  downloadQueueAtom,
  installedGamesAtom,
  playingGamesAtom,
} from '#/core/stores'
import {
  extractDesktopResource,
  extractMobileResource,
  extractWebResource,
  getCurrentOSResource,
  isBrowsingOnDesktop,
} from '#/utils'

import { userLibraryQueryKey, useUserLibrary, useUserProfile } from '../query'
import { useGameDialog } from './dialog'

export * from './dialog'
export * from './filter'

type GameButtonProps = {
  text: GameButtonState
  onClick?: () => Promise<void>
}

export const useGame = (game: GameDetails) => {
  const { data: library } = useUserLibrary()
  const { data: profile } = useUserProfile()
  const { openDialog } = useGameDialog()
  const downloadQueue = useAtomValue(downloadQueueAtom)
  const installedGames = useAtomValue(installedGamesAtom)
  const playlingGames = useAtomValue(playingGamesAtom)

  /* Extract Resources */
  const { mobileResource, webResource, desktopResource, userOSResource } =
    useMemo(() => {
      return {
        mobileResource: extractMobileResource(game.latestVersion?.resources),
        webResource: extractWebResource(game.latestVersion?.resources),
        desktopResource: extractDesktopResource(game.latestVersion?.resources),
        userOSResource: getCurrentOSResource(game.latestVersion?.resources),
      }
    }, [game.latestVersion?.resources])

  /* Platform support */
  const isUserOSSupported = userOSResource !== undefined
  const isWebSupported = webResource !== undefined
  const isMobileSupported =
    Object.values(mobileResource).filter(Boolean).length > 0
  const isSupportedOnDesktop =
    isBrowsingOnDesktop && (isUserOSSupported || isWebSupported)

  /* Availability */
  const isAvailableBrowsingPlatform = isUserOSSupported || isWebSupported
  const onlyAvailableToPlayOnDesktop = isUserOSSupported && !isWebSupported
  const onlyAvailableToPlayOnWeb = isWebSupported && !isUserOSSupported

  /* Game Condition */
  const isAddedToLibrary = Boolean(library?.some((item) => item.slug === game.slug))
  const { isInstalling, isQueueing } = useMemo(
    () => ({
      isInstalling: downloadQueue.some(
        (item, index) => item.slug === game.slug && index === 0
      ),
      isQueueing: downloadQueue.some(
        (item, index) => item.slug === game.slug && index !== 0
      ),
    }),
    [downloadQueue, game.slug]
  )
  const installedVersion: StoreInstalledGames[string] | undefined =
    installedGames[game.slug]
  const needToUpdate =
    installedVersion &&
    game.latestVersion &&
    game.latestVersion.tag !== installedVersion.version
  const isPlaying = playlingGames.has(game.slug || '')

  const isComingsoon = game.mode === 'coming-soon'

  const state = useMemo<GameButtonState | undefined>(() => {
    if (game.mode === 'coming-soon') return GameButtonState.ComingSoon
    if (isPlaying) {
      return GameButtonState.Playing
    }
    if (isInstalling) {
      return GameButtonState.Installing
    }
    if (isQueueing) {
      return GameButtonState.Queued
    }
    const isInstalled = Boolean(installedVersion)
    if (isSupportedOnDesktop) {
      if (onlyAvailableToPlayOnWeb) {
        return GameButtonState.Play
      }
      if (isInstalled) {
        if (needToUpdate) {
          return GameButtonState.Update
        }

        return GameButtonState.Play
      }

      return GameButtonState.Install
    }
    if (isInstalled) {
      return GameButtonState.AddToLibrary
    }

    return GameButtonState.NotSupported
  }, [
    game,
    isPlaying,
    isInstalling,
    isQueueing,
    installedVersion,
    isSupportedOnDesktop,
    onlyAvailableToPlayOnWeb,
    needToUpdate,
  ])

  /* Utilities */
  const launchGame = useMemoizedFn(async () => {
    const isInHouseGame = game.organization?.slug === 'sky-mavis'
    const screen = {
      width: Math.floor(window.screen.width * 0.75),
      height: Math.floor(window.screen.height * 0.75),
    }
    const webGameOptions: { url?: string; version?: string } = {}
    if (onlyAvailableToPlayOnWeb && !isUserOSSupported) {
      webGameOptions.url = webResource?.downloadUrl
      webGameOptions.version = game.latestVersion?.tag
      if (game.slug === 'raylights' && webResource?.downloadUrl) {
        window.open(webResource?.downloadUrl, '_blank')

        return
      }
    }
    const result = await handlers.launchGame(game.slug, isInHouseGame, {
      ...webGameOptions,
      ...screen,
    })
    match(result)
      .with(false, () => toast.error('Unexpected Error. Cannot open this game'))
      .with(P.string, message => toast.error(message))
      .otherwise(() => void 0)
  })

  const addToLibrary = useMemoizedFn(async () => {
    if (isAddedToLibrary) return
    const [accessToken] = (await handlers.getCookie(COOKIE_NAME.ACCESS_TOKEN)) || []
    if (!accessToken) return
    const { response, error } = await services.request('put /v2/users/games/[slug]', { slug: game.slug })
    if (response.status >= 500) {
      toast(errorMessages.serverBusy)

      return
    } else if (error) {
      /*
        Avoid this message while library not loaded yet
        https://github.com/axieinfinity/mavis-hub-server-v2/blob/b9688876de5d84bcc5142ef4c45329f88876868d/internal/repositories/db/game.go#L297
      */
      if (!error.message.includes('game existed in library')) {
        toast.error(
          `An error occured while adding ${game.name} to your library: ${error.message}`
        )
      }

      return
    } else {
      await queryClient.invalidateQueries({ queryKey: userLibraryQueryKey(profile) })
    }
  })

  const gameButtonProps = useMemo<GameButtonProps>(() => {
    if (!game || !state) return { text: GameButtonState.NotSupported }
    if (!profile) {
      return { text: GameButtonState.Install, onClick: async () => void 0 }
    }

    const propsCollections: Record<GameButtonState, GameButtonProps> = {
      [GameButtonState.ComingSoon]: {
        text: GameButtonState.ComingSoon,
      },
      [GameButtonState.Queued]: {
        text: GameButtonState.Queued,
      },
      [GameButtonState.Install]: {
        text: GameButtonState.Install,
        onClick: async () => {
          if (!isAddedToLibrary) await addToLibrary()
          openDialog('install', game)
        },
      },
      [GameButtonState.Installing]: {
        text: GameButtonState.Installing,
      },
      [GameButtonState.Play]: {
        text: GameButtonState.Play,
        onClick: async () => {
          if (!isAddedToLibrary) await addToLibrary()
          await launchGame()
        },
      },
      [GameButtonState.Playing]: {
        text: GameButtonState.Playing,
      },
      [GameButtonState.Update]: {
        text: GameButtonState.Update,
        onClick: async () => {
          if (!isAddedToLibrary) await addToLibrary()
          openDialog('update', game)
        },
      },
      [GameButtonState.NotSupported]: {
        text: GameButtonState.NotSupported,
      },
      [GameButtonState.AddToLibrary]: {
        text: GameButtonState.AddToLibrary,
        onClick: () => addToLibrary(),
      },
    }

    return propsCollections[state]
  }, [
    addToLibrary,
    game,
    isAddedToLibrary,
    launchGame,
    openDialog,
    profile,
    state,
  ])

  return {
    state,
    gameButtonProps,
    isComingsoon,
    isAddedToLibrary,
    onlyAvailableToPlayOnDesktop,
    onlyAvailableToPlayOnWeb,
    isAvailableBrowsingPlatform,
    isInstalling,
    isPlaying,
    installed: installedVersion,
    isInstalled: Boolean(installedVersion),
    isQueueing,
    isMobileSupported,
    isWebSupported,
    launchGame,
    addToLibrary,

    mobileResource,
    webResource,
    desktopResource,
  }
}

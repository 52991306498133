import { GameDetails } from '@axieinfinity/hub-services'
import { useEffect } from 'react'

import { useCaptureEvent, useGameFilter, usePaginationQuery } from '#/hooks'
import { usePublicGames } from '#/hooks/query'
import { serializeNavigateParams } from '#/utils'

import { GameGrid } from '../game'

export const GameList: React.FC = () => {
	const captureEvent = useCaptureEvent()

	const { filters } = useGameFilter()
	const { page } = usePaginationQuery()

	const { isLoading, error, data } = usePublicGames(filters, { page: page ?? 1, pageSize: 18 })

	useEffect(() => {
		if (filters && !data?.data) return
		captureEvent('Game Searching results Updated!', {
			games: data?.data.map(({ id, name, slug }) => ({ id, name, slug })),
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data?.data])

	const navigationParams = serializeNavigateParams({
		from: '/games',
		name: 'Browse Games',
		filters,
	})

	function onClickGameCard(game: GameDetails) {
		const { id, name, slug, organization } = game
		const genres = game.genres?.map((g) => g.slug)
		captureEvent('Click Game Card on List', {
			game: { id, name, slug, genres },
			game_org: {
				name: organization?.name,
				slug: organization?.slug,
			},
		})
	}

	return (
		<GameGrid
			{...{
				isLoading,
				error,
				data: data?.data,
				navigationParams,
				onClickGameCard,
				pagination: data?.pagination,
			}}
		/>
	)
}

import * as app from './app'
import * as game from './game'
import * as system from './system'

const handlers = {
  ...app,
  ...game,
  ...system,
}

export default handlers

import { GameDetails } from '@axieinfinity/hub-services'
import { useResponsive } from '@axieinfinity/konan'
import cx from 'classnames'
import { useEffect, useRef } from 'react'

import { Vote } from '../vote'
import { GameActions } from './actions'
import { PlayOnMobile } from './mobile'
import { SocialShare } from './share'
import styles from './shared.module.scss'

type Props = Pick<React.HTMLAttributes<HTMLDivElement>, 'className'> & {
  minimal?: boolean
  game: GameDetails
}

export const Controls: React.FC<Props> = ({
  className,
  minimal = false,
  game,
}) => {
  const { lg: isLargeDimension } = useResponsive()

  const containerRef = useRef<HTMLDivElement>(null)
  const prevEntry = useRef<IntersectionObserverEntry>()

  useEffect(() => {
    if (minimal || !isLargeDimension) return
    if (!containerRef.current) return

    const element = containerRef.current
    const headerElement = document.getElementById('game-details-header')
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!headerElement) return
        if (prevEntry.current?.isIntersecting && entry.isIntersecting) {
          const direction =
            entry.boundingClientRect.top <
              prevEntry.current.boundingClientRect.top
              ? 'downwards'
              : 'upwards'

          if (direction === 'downwards' && entry.intersectionRatio <= 0.7) {
            headerElement.classList.toggle('stuck', true)
            headerElement.setAttribute('style', `padding-top: ${(document.getElementById('navbar')?.clientHeight || 0)}px !important`)
          } else if (direction === 'upwards' && entry.intersectionRatio > 0.7) {
            headerElement.classList.remove('stuck')
            headerElement.setAttribute('style', 'padding-top: 0px !important')
          }
        } else {
          if (entry.isIntersecting && !prevEntry.current?.isIntersecting) {
            headerElement.classList.remove('stuck')
            headerElement.setAttribute('style', 'padding-top: 0px !important')
          } else if (
            !entry.isIntersecting &&
            prevEntry.current?.isIntersecting
          ) {
            headerElement.classList.add('stuck')
            headerElement.setAttribute('style', `padding-top: ${(document.getElementById('navbar')?.clientHeight || 0)}px !important`)
          }
        }

        prevEntry.current = entry
      },
      { threshold: Array.from({ length: 51 }, (_, index) => index * 0.02) }
    )

    observer.observe(element)

    return () => observer.unobserve(element)
  }, [minimal, isLargeDimension])

  const isDuringEvent = Boolean(game.event)

  return (
    <div
      className={cx(styles.controls, className, { [styles.horizontal]: minimal })}
      ref={containerRef}
    >
      <GameActions
        game={game}
        minimal={minimal}
      />
      <PlayOnMobile game={game} minimal={minimal} />
      <SocialShare game={game} minimal={minimal} />
      {!minimal && isDuringEvent && <Vote game={game} />}
    </div>
  )
}

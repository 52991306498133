import {
	AxieRotationCaptcha,
	AxieRotationCaptchaData,
	AxieRotationCaptchaRef,
} from '@axieinfinity/captcha'
import {
	Eye02Icon,
	EyeDisableIcon,
	InformationCircle01Icon,
} from '@axieinfinity/dango-icons'
import type { InputProps } from '@axieinfinity/konan'
import {
	Button,
	Checkbox,
	Input,
	Intent,
	Typography,
} from '@axieinfinity/konan'
import { zodResolver } from '@hookform/resolvers/zod'
import config from '@mavishub/config'
import {
	LoginRequest,
	loginRequestSchema,
	ResponseStatusCode,
} from '@axieinfinity/hub-services'
import { useMemoizedFn } from 'ahooks'
import cx from 'classnames'
import { useSetAtom } from 'jotai'
import { useEffect, useReducer, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { match, P } from 'ts-pattern'

import { persistStorage } from '#/core/local-storage'
import { logger } from '#/core/logger'
import { services } from '#/core/services'
import { twoFactorTokenAtom } from '#/core/stores'
import { useCaptureEvent, useCheatCode, useHandler } from '#/hooks'
import { digestMessage } from '#/utils'

import styles from './form.module.scss'

type Props = React.HTMLAttributes<HTMLFormElement> & {
	onSuccess?: () => void
	setError: (error: string) => void
}

export const LoginForm: React.FC<Props> = ({
	className,
	onSuccess,
	setError,
	...props
}) => {
	const { handleLogin } = useHandler()
	const cheatInputRef = useCheatCode()
	const captchaRef = useRef<AxieRotationCaptchaRef>(null)
	const {
		watch,
		reset,
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<LoginRequest>({
		defaultValues: { email: '', password: '' },
		resolver: zodResolver(loginRequestSchema),
	})

	const setTwoFactorInfo = useSetAtom(twoFactorTokenAtom)

	const [isPasswordVisible, onTogglePasswordVisibility] = useReducer(
		value => !value,
		false
	)
	const [isSession, onToggleIsSession] = useReducer(value => {
		persistStorage.setIsSession(!value)

		return !value
	}, persistStorage.getIsSession())
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const captureEvent = useCaptureEvent()

	const onSubmit = useMemoizedFn(() => {
		try {
			setError('')
			captchaRef.current?.init()
			captureEvent('Login - Captcha loaded')
		} catch (error) {
			logger.error(error, { error })
			captureEvent('Login - Captcha init failed', { error })
		}
	})

	const onCaptchaFailure = useMemoizedFn((message: string) => {
		setError('The captcha is incorrect. Please try again.')
		captureEvent('Login', { error: message })
		captchaRef.current?.close()
	})

	const onCaptchaSuccess = useMemoizedFn(
		async (captchaData: AxieRotationCaptchaData) => {
			setIsLoading(true)
			let err = null
			const { response, error, result } = await services.request(
				'post /v2/auth/login',
				{
					headers: { 'x-captcha': captchaData.token },
					body: {
						email: watch().email,
						password: await digestMessage(watch().password),
					},
				}
			)
			if (response.status >= 500) {
				err = 'Login failed'
				setError('An unexpected error occurred, please try again later')
			} else if (error) {
				match(error)
					.with(
						{
							code: ResponseStatusCode.TwoFactorRequired,
							details: { mfaToken: P.string, expiresAt: P.string },
						},
						({ details }) => setTwoFactorInfo(details)
					)
					.otherwise(() => {
						err = error.message || 'Something went wrong'
						setError(err)
					})
			} else {
				await handleLogin(result, isSession)
				reset()
				onSuccess?.()
			}
			captureEvent('Login', { err, remember_me: !isSession })
			setIsLoading(false)
		}
	)

	function getInputMightErrorProps(error?: string): InputProps {
		return { intent: error ? Intent.Danger : Intent.Default }
	}

	const PasswordFieldIcon = isPasswordVisible ? Eye02Icon : EyeDisableIcon

	useEffect(() => {
		if (errors.email?.message || errors.password?.message) {
			setError(errors.email?.message || errors.password?.message || '')
		}
	}, [errors.email?.message, errors.password?.message, setError])

	const shouldDisableCTA =
		Boolean(errors.email?.message || errors.password?.message) ||
		!watch().email ||
		!watch().password

	return (
		<form
			{...props}
			className={cx(styles.form, className)}
			onSubmit={handleSubmit(onSubmit)}
		>
			<Input
				{...register('email')}
				{...getInputMightErrorProps(errors.email?.message)}
				autoFocus
				label="Email"
			/>
			<Input
				{...register('password')}
				{...getInputMightErrorProps(errors.password?.message)}
				label="Password"
				autoComplete="current-password"
				type={isPasswordVisible ? 'text' : 'password'}
				suffixIcon={
					<PasswordFieldIcon
						className={styles.icon}
						onClick={onTogglePasswordVisibility}
					/>
				}
			/>

			<div className={styles.utils}>
				<Checkbox
					label="Remember me"
					checked={!isSession}
					ref={cheatInputRef}
					onClick={onToggleIsSession}
				/>
				{/* <a className={styles.forgotPassword} href="">Forgot password?</a> */}
			</div>

			<div className={styles.term}>
				<Typography.Text className={styles.text}>
					<InformationCircle01Icon />
					By continuing, you agree to our
					<a
						className={styles.link}
						href="https://cdn.skymavis.com/files/terms-of-use.pdf"
						target="_blank"
						rel="noopener noreferrer"
						onClick={() => captureEvent('Click View Terms of Use')}
					>
						Terms of Use
					</a>
				</Typography.Text>
			</div>

			<AxieRotationCaptcha
				ref={captchaRef}
				url={config.captchaEndpoint}
				apiKey={config.captchaKey}
				onSuccess={onCaptchaSuccess}
				onFailure={onCaptchaFailure}
			/>

			<Button
				intent={Intent.Primary}
				loading={isLoading}
				disabled={shouldDisableCTA}
				type="submit"
				text="Login"
			/>
		</form>
	)
}

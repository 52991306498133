import { Button, Intent, Modal, Typography } from '@axieinfinity/konan'
import { useEffect, useState } from 'react'

import styles from './open-external.module.scss'

export const OpenExternalDialog = () => {
  const [requestUrl, setRequestUrl] = useState<string | undefined>(undefined)

  useEffect(() => {
    const unsubscribe = window.bridge?.kataru.on(
      'app:request_open_external',
      (_, url) => setRequestUrl(url)
    )

    return () => void unsubscribe?.()
  }, [])

  const confirm = (close: () => void, trust?: boolean) => () => {
    if (requestUrl) {
      window.bridge?.kataru.invoke('app:confirm_open_external', requestUrl, trust)
    }
    close()
  }

  return (
    <Modal
      isDismissable
      isOpen={Boolean(requestUrl)}
      target={() => null}
      onOpenChange={(isOpen) =>
        isOpen === false ? setRequestUrl(undefined) : void 0}
    >
      {({ close }) => (
        <div className={styles.content}>
          <Typography.Heading level="5" className={styles.header}>Hold up!</Typography.Heading>
          <div className={styles.entry}>
            This link will take you to <span className={styles.link}>{requestUrl}</span>
            <br />
            Are you sure you want to go there?
          </div>
          <div className={styles.footer}>
            <div className={styles.actions}>
              <Button text="Cancel" onClick={close} />
              <Button text="Confirm" intent={Intent.Primary} onClick={confirm(close)} />
            </div>
            <a className={styles.remember} onClick={confirm(close, true)}>Trust this domain</a>
          </div>
        </div>
      )}
    </Modal>
  )
}

export function isEmpty(value: unknown) {
  if (value === null || value === undefined) {
    return true
  }

  if (typeof value === 'string' || Array.isArray(value)) {
    return value.length
  }

  if (typeof value === 'object') {
    return Object.keys(value).length === 0
  }

  if (typeof value === 'number') {
    return false
  }

  return !value
}

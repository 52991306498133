import { MenuLineHorizontal01Icon } from '@axieinfinity/dango-icons'
import { useSetAtom } from 'jotai'

import { mobileMenuAtom } from '#/core/stores'

import styles from './trigger.module.scss'

export const MobileMenuTrigger: React.FC = () => {
  const setMobileMenu = useSetAtom(mobileMenuAtom)

  function toggleMenuOverlay() {
    setMobileMenu(({ isVisible }) => ({ isVisible: !isVisible }))
  }

  return (
    <button className={styles.button} onClick={toggleMenuOverlay}>
      <MenuLineHorizontal01Icon size={24} />
    </button>
  )
}

import { COOKIE_NAME } from '@axieinfinity/kukki'
import { AuthenticationResponse } from '@axieinfinity/hub-services'
import { useMemoizedFn } from 'ahooks'
import { toast } from 'sonner'

import { clearTokens } from '#/core/browser/request'
import { cookie, defaultCookieOptions, oneYearExpiryDate } from '#/core/cookie'
import handlers from '#/core/handlers'
import { logger } from '#/core/logger'
import { queryClient } from '#/core/react-query'
import { services } from '#/core/services'
import { isBrowsingOnDesktop } from '#/utils'

import { userProfileQueryKey } from './query'

export const useHandler = () => {
	const handleLogin = useMemoizedFn(function (authResp: AuthenticationResponse, isSession: boolean) {
		cookie.set(COOKIE_NAME.ACCESS_TOKEN, authResp.accessToken, {
			...defaultCookieOptions,
			isSession,
			expires: new Date(authResp.accessTokenExpiresAt),
			maxAge: authResp.accessTokenExpiresIn,
		})

		cookie.set(COOKIE_NAME.REFRESH_TOKEN, authResp.refreshToken, {
			...defaultCookieOptions,
			isSession,
			expires: oneYearExpiryDate(),
		})

		return queryClient.invalidateQueries({ queryKey: userProfileQueryKey })
	})

	const handleLogout = useMemoizedFn(async function () {
		const accessToken = cookie.get(COOKIE_NAME.ACCESS_TOKEN)
		if (!accessToken) return

		try {
			const { response, error } = await services.request('post /v2/auth/logout')
			if (response.status >= 500) return toast.error('An unexpected error occurred. Please try again later')
			if (error) return toast.error(error.message)
			clearTokens()
		} catch (error) {
			logger.error(error, { error }, 'handleLogout')
		} finally {
			queryClient.invalidateQueries({ queryKey: userProfileQueryKey })
		}
	})

	const handleGetAccessToken = useMemoizedFn(async function () {
		return cookie.get(COOKIE_NAME.ACCESS_TOKEN)
	})

	const handleSetClipboard = useMemoizedFn(function (text: string) {
		if (isBrowsingOnDesktop) return handlers.setClipboard(text)
		else return navigator.clipboard.writeText(text)
	})

	return {
		handleLogin,
		handleLogout,
		handleGetAccessToken,
		handleSetClipboard,
	}
}


import { Button, ButtonVariant } from '@axieinfinity/konan'
import { useNavigate } from 'react-router'

import { isBrowsingOnWeb } from '#/utils'

import type { NavigationItem, ProfileMenuItem } from '../types'
import { NavItem } from './navigation-item'
import { Profile } from './profile'
import styles from './section.module.scss'

type Props = {
  navigationItems: NavigationItem[]
  profileMenuItems: ProfileMenuItem[]
}

export const DesktopMenu: React.FC<Props> = ({ navigationItems, profileMenuItems }) => {
  const navigate = useNavigate()

  const handleNavigation = () => {
    navigate('/about')
  }

  return (
    <div className={styles.container}>
      <div className={styles.group}>
        {navigationItems.map((props, index) => <NavItem key={index} {...props} />)}
      </div>

      <div className={styles.group}>
        {isBrowsingOnWeb && (
          <Button
            variant={ButtonVariant.Default}
            text="Install Hub"
            onClick={handleNavigation}
          />
        )}

        <Profile items={profileMenuItems} />
      </div>
    </div>
  )
}

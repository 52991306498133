import {
  BookmarkIcon,
  BoxSearch03Icon,
  Calendar03Icon,
  Home02Icon,
  Logout01Icon,
  UserGroup02Icon,
} from '@axieinfinity/dango-icons'
import { useResponsive } from '@axieinfinity/konan'
import cx from 'classnames'
import { useMemo, useRef } from 'react'

import { useHandler } from '#/hooks'
import { isBrowsingOnDesktop, isUsingDarwin } from '#/utils'

import { BetaBadge } from './beta-badge'
import { DesktopMenu } from './desktop-menu/section'
import { Logo } from './logo'
import { MobileMenuOverlay, MobileMenuTrigger } from './mobile-menu'
import styles from './navbar.module.scss'
import type { NavigationItem, ProfileMenuItem } from './types'
import { WindowHandler } from './window-handler'

export const NavigationBar: React.FC = () => {
  const { handleLogout } = useHandler()
  const { lg } = useResponsive()

  const navRef = useRef<HTMLElement>(null)

  const navigationItems = useMemo(() => {
    const result: NavigationItem[] = [
      { label: 'Home', symbol: Home02Icon, href: '/' },
      { label: 'Browse', symbol: BoxSearch03Icon, href: '/games' },
      { label: 'Community', symbol: UserGroup02Icon, href: '/greenlight' },
      { label: 'Event', symbol: Calendar03Icon, href: '/axie-game-jam/2023' },
    ]

    if (isBrowsingOnDesktop) {
      result.splice(2, 0, { label: 'Library', symbol: BookmarkIcon, href: '/library', authRequired: true })
    }

    return result
  }, [])

  const profileMenuItems = useMemo((): ProfileMenuItem[] => [
    {
      label: 'Log out',
      symbol: Logout01Icon,
      isNegative: true,
      autoCloseMenu: false,
      onClick: handleLogout,
    },
  ], [handleLogout])

  const isSmallDimension = !lg

  return (
    <>
      {isSmallDimension && (
        <MobileMenuOverlay
          navRef={navRef}
          navigationItems={navigationItems}
          profileMenuItems={profileMenuItems}
        />)}

      <nav className={cx(styles.nav, { [styles.hasGrabZone]: isBrowsingOnDesktop })} ref={navRef} id="navbar">
        {isBrowsingOnDesktop && <div className={styles.grabZone}>{!isUsingDarwin && <WindowHandler />}</div>}

        <div className={styles.container}>
          <Logo />
          {!isSmallDimension && <DesktopMenu navigationItems={navigationItems} profileMenuItems={profileMenuItems} />}
          {isSmallDimension && <MobileMenuTrigger />}
          <BetaBadge />
        </div>
      </nav>
    </>
  )
}

import { ExternalLinkIcon } from '@axieinfinity/dango-icons'
import type { Banner } from '@axieinfinity/hub-services'
import { Button, ButtonVariant, Intent } from '@axieinfinity/konan'
import { useMemoizedFn } from 'ahooks'
import cx from 'classnames'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { match, P } from 'ts-pattern'

import { LoadableImage } from '#/components/common/loader/loadable-image'
import { Skeleton } from '#/components/common/loader/skeleton'
import { useCaptureEvent } from '#/hooks'
import { useHomeBanner } from '#/hooks/query'

import styles from './banner.module.scss'
import { Dots } from './dots'

export type ActiveBanner = Banner & { index: number }

type Props = {
  onChange?: (src: string) => void
}

export const HomeBanner: React.FC<Props> = ({ onChange }) => {
  const captureEvent = useCaptureEvent()

  const [isPaused, setIsPaused] = useState<boolean>(false)
  const [activeItem, setActiveItem] = useState<ActiveBanner | undefined>(undefined)

  const { isLoading, data } = useHomeBanner()

  const handleBannerChange = useMemoizedFn((banner: ActiveBanner) => {
    setActiveItem(banner)
    onChange?.(banner?.thumbnailUrl || banner?.thumbnailURL || banner?.bannerUrl)
  })

  if (activeItem === undefined && data !== undefined && data.length > 0) {
    handleBannerChange({ ...data[0], index: 0 })
  }

  const handlePointerEnter = useMemoizedFn(() => {
    if (!isPaused) {
      setIsPaused(true)
    }
  })

  const handlePointerLeave = useMemoizedFn(() => {
    if (isPaused) {
      setIsPaused(false)
    }
  })

  const handleBannerButtonClick = useMemoizedFn(
    (bannerId: number, bannerTitle: string, buttonText: string, buttonHref: string) => () => {
      captureEvent('Click Banner', { bannerId, bannerTitle, buttonText, buttonHref })
    }
  )

  return match({ isLoading, data })
    .with({ isLoading: true }, () => (
      <section className={styles.section}>
        <Skeleton height={400} corner="rounded-xl" />
        <Skeleton width="50%" height={4} corner="rounded" data-slot="dots-loader" />
      </section>
    ))
    .with({ data: undefined }, () => null)
    .with({ data: P.when((data) => data?.length === 0) }, () => null)
    .with({ data: P.not(P.nullish) }, ({ data }) => (
      <section className={styles.section}>
        <figure
          className={styles.figure}
          onPointerEnter={handlePointerEnter}
          onPointerLeave={handlePointerLeave}
        >
          <LoadableImage
            containerClassName={styles.imageContainer}
            className={styles.image}
            borderRadius={16}
            fill
            src={activeItem?.thumbnailUrl || activeItem?.thumbnailURL || activeItem?.bannerUrl}
          />

          <figcaption className={styles.caption}>
            <div className={styles.wrapper}>
              <div className={styles.content}>
                {typeof activeItem?.title !== 'undefined' && (
                  <div className={styles.heading}>{activeItem.title}</div>
                )}
                {typeof activeItem?.subTitle !== 'undefined' && (
                  <div className={styles.text}>{activeItem.subTitle}</div>
                )}
              </div>

              {activeItem !== undefined && activeItem.assetsAction.length > 0 && (
                <div className={styles.actions}>
                  {activeItem.assetsAction.map(({ cta, type, viewText, data }) => (
                    <Link
                      key={JSON.stringify({ type, viewText, data })}
                      to={data}
                      target={cta === 'external' ? '_blank' : undefined}
                      rel={cta === 'external' ? 'noopener noreferrer' : undefined}
                    >
                      <Button
                        className={cx(styles.button, { [styles.secondary]: type === 'secondary' })}
                        intent={Intent.Primary}
                        {...(type === 'secondary' && { variant: ButtonVariant.Plain })}
                        {...(cta === 'external' && { rightIcon: ExternalLinkIcon })}
                        text={<div>{viewText}</div>}
                        onClick={handleBannerButtonClick(activeItem.id, activeItem.title, viewText, data)}
                      />
                    </Link>
                  ))}
                </div>
              )}
            </div>
          </figcaption>
        </figure>

        {data !== undefined && data.length > 1 && (
          <div className={styles.dots}>
            <Dots
              items={data}
              isPaused={isPaused}
              activeItem={activeItem}
              onChange={handleBannerChange}
            />
          </div>
        )}
      </section>
    ))
    .exhaustive()
}

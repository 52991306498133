import { WC_DEFAULT_PROJECT_ID } from './constants'
import { RoninMobileConnector } from './mobile-connector'
import { MobileConnectorOptions } from './types'

export const createRoninMobileConnector = async (options: MobileConnectorOptions) => {
  const connector = new RoninMobileConnector()
  await connector.init({
    ...options,
    projectId: options.projectId || WC_DEFAULT_PROJECT_ID,
  })

  return connector
}

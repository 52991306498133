import { useMemoizedFn } from 'ahooks'
import { useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router'
import { match, P } from 'ts-pattern'

import { BlurryBackground, type Ref as BlurryBackgroundRef } from '#/components/views/blurry-background'
import {
  Controls,
  DescriptionSection,
  ErrorPageContent,
  Gallery,
  GameDetail,
  HeaderSection,
  Reviews,
  SystemRequirementsSection,
} from '#/components/views/game-details'
import { ValidatingIndicator } from '#/components/views/indicators/validate'
import { LoadingOverlay } from '#/components/views/loading/overlay'
import { useMoss } from '#/hooks'
import { useGameDetails } from '#/hooks/query'

import styles from './game-details.module.scss'

export const gameDetailsContainerId = 'game-details-content-container'

export const GameDetailsScreen: React.FC = () => {
  const { captureScreen } = useMoss()
  const { slug } = useParams()
  const { isLoading, error, data } = useGameDetails(slug)

  const blurryBackgroundRef = useRef<BlurryBackgroundRef>(null)

  const handleGalleryChange = useMemoizedFn((src: string) => {
    blurryBackgroundRef.current?.updateSource(src)
  })

  useEffect(() => {
    if (data?.name) {
      captureScreen('Game Details - ' + data?.name)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.name])

  const defaultBlurryBackgroundSrc = match(data?.gallery?.[0])
    .with(P.nullish, () => '')
    .with({ type: 'embed' }, ({ thumbnail }) => thumbnail)
    .with({ type: 'image' }, ({ src }) => src)
    .exhaustive()

  return (
    <main className={styles.screen}>
      {match({ isLoading, error, data })
        .with({ isLoading: true }, () => <LoadingOverlay fit />)
        .with(
          {
            error: {
              name: 'HTTPError',
              message: P.when((message) => message.includes('404')),
            },
          },
          () => <ErrorPageContent code={404} />
        )
        .with({ error: P.not(P.nullish) }, () => (
          <ErrorPageContent code={500} />
        ))
        .with({ data: undefined }, () => null)
        .with({ data: null }, () => <ErrorPageContent code={404} />)
        .with({ data: P.not(P.nullish) }, ({ data: game }) => (
          <>
            <Helmet>
              <title>{game.name} on Mavis Hub</title>
              <meta name="description" content={game.description} />
            </Helmet>

            <BlurryBackground
              ref={blurryBackgroundRef}
              defaultSrc={defaultBlurryBackgroundSrc}
            />

            <div className={styles.container} id={gameDetailsContainerId}>
              <HeaderSection {...game} />

              <section className={styles.section}>
                <div data-slot="gallery">
                  <Gallery items={game.gallery} key={game.slug} onChange={handleGalleryChange} />
                </div>

                <div data-slot="aside">
                  <Controls game={game} />
                  <GameDetail {...game} />
                </div>

                <div data-slot="description">
                  <DescriptionSection data={game.about} />
                </div>

                <div data-slot="requirement">
                  <SystemRequirementsSection {...game} />
                </div>

                {game.event && (
                  <div data-slot="review">
                    <Reviews slug={game.slug} />
                  </div>
                )}
              </section>
            </div>

            <ValidatingIndicator isVisible={isLoading} />
          </>
        ))
        .otherwise(() => null)}
    </main>
  )
}
